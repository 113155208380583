import * as React from 'react';
import '../../styles/global.scss';
import '../../styles/normalize.scss';
import '../../styles/typography.scss';
import '../../styles/settings.scss';
import RootLayout from '../../components/RootLayout/RootLayout';
import Headers from '../../components/Headers/Headers';
import Footer from '../../components/Footer/Footer';
import ScroolUpButton from '../../components/ScroolUpButton/ScroolUpButton';
import BlogArticleWebDesignProcess from '../../components/BlogArticles/BlogArticleWebDesignProcess';

const BlogProcessSiteMaking = () => {
  const title_top = 'How the Process Works';
  const title_middle = 'of Creating a Website';
  const siteTitle = 'How the Website Creation Process Works Step by Step - Nowoczesna witryna';
  const siteDescription =
    'Creating a website is a complex process that involves planning, design, coding, and testing. Each stage is crucial to the success of a functional and visually appealing site. Here, we outline the steps to building a modern website.';
  const siteUrl = 'https://www.nowoczesnawitryna.com/blog/how-the-website-creation-process-works/';
  const siteUrlOtherLang = 'https://www.nowoczesnawitryna.pl/blog/jak-przebiega-proces-tworzenia-strony/';

  return (
    <>
      <RootLayout title={siteTitle} url={siteUrl} urlOtherLang={siteUrlOtherLang} description={siteDescription} />
      <Headers title_top={title_top} title_middle={title_middle} active="blog" />
      <ScroolUpButton />
      <BlogArticleWebDesignProcess />
      <Footer />
    </>
  );
};

export default BlogProcessSiteMaking;
